<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <router-link to="/workers">Trabajadores</router-link> >
      <span
        v-text="
          $route.params.id ? 'Editar trabajador' : 'Crear nuevo trabajador'
        "
      ></span>
    </div>
    <div class="md-layout-item md-medium-size-100 md-size-100">
      <worker-form :data-background-color="getTheme"> </worker-form>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-size-100"
      v-if="$route.params.id"
    >
      <worker-address-form :data-background-color="getTheme">
      </worker-address-form>
    </div>
  </div>
</template>

<script>
import WorkerForm from "../components/Workers/WorkerForm";
import WorkerAddressForm from "../components/Workers/WorkerAddressForm";
import { mapGetters } from "vuex";
export default {
  components: {
    WorkerForm,
    WorkerAddressForm,
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
};
</script>