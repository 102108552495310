<template>
  <v-expansion-panels focusable v-bind:style="{ 'padding-bottom': '20px' }">
    <v-expansion-panel>
      <v-expansion-panel-header
        v-bind:style="{ 'background-color': '#2DCB7F', color: 'white' }"
      >
        <h4
          class="title"
          v-text="(isAddress ? 'Edit' : 'Create') + ' address'"
        ></h4>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="form" v-model="valid">
          <v-container class="grey lighten-5">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="street"
                  :rules="fillRules"
                  label="Street"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="number"
                  :rules="fillRules"
                  label="Number"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="extra"
                  label="Department Number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="grey lighten-5">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="city"
                  :rules="fillRules"
                  label="City"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="state"
                  :rules="fillRules"
                  label="State"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="country"
                  :rules="fillRules"
                  label="Country"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="grey lighten-5">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="reference"
                  label="Reference"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4"> </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              @click="handleSubmit(true)"
            >
              Set
            </v-btn>
          </v-container>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "worker-address-form",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  watch: {
    "$route.params.id": function () {
      this.address = null;
      this.getAddress();
      if (this.address) {
        this.editMode = true;
      }
    },
  },
  data() {
    return {
      address: null,
      valid: false,
      fillRules: [(v) => !!v || "Field is required"],
      street: "",
      number: "",
      extra: "",
      state: "",
      city: "",
      country: "",
      reference: "",
      editMode: false,
      errors: { street: "", number: "", state: "", city: "", country: "" },
    };
  },
  computed: {
    isAddress() {
      if (this.address) return true;
      else return false;
    },
  },
  created: function () {
    this.getAddress();
  },
  mounted() {},
  methods: {
    validateForm() {
      this.$refs.form.validate();
    },
    handleSubmit(stay) {
      // if (true) {
      if (!this.isAddress) {
        this.createAddress(stay);
      } else {
        this.updateAddress(stay);
      }
      // }
    },
    getAddress() {
      this.$store
        .dispatch("worker/getAddress", this.$route.params.id)
        .then((data) => {
          this.street = data.street;
          this.number = data.number;
          this.extra = data.extra;
          this.state = data.state;
          this.city = data.city;
          this.country = data.country;
          this.reference = data.reference;
          this.address = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createAddress() {
      this.$store
        .dispatch("worker/createAddress", {
          id: this.$route.params.id,
          street: this.street,
          number: this.number,
          city: this.city,
          state: this.state,
          country: this.country,
          extra: this.extra,
          reference: this.reference,
        })
        .then((data) => {
          if (data.status == 201) {
            this.$toast.success("Address has been created");
          }
        })
        .catch(() => {
          this.$toast.error("Something went wrong");
        });
    },
    updateAddress() {
      this.$store
        .dispatch("worker/updateAddress", {
          id: this.$route.params.id,
          street: this.street,
          number: this.number,
          city: this.city,
          state: this.state,
          country: this.country,
          extra: this.extra,
          reference: this.reference,
        })
        .then((data) => {
          this.street = data.street;
          this.number = data.number;
          this.extra = data.extra;
          this.state = data.state;
          this.city = data.city;
          this.country = data.country;
          this.reference = data.reference;
          this.address = data;

          if (data.status == 200) {
            this.$toast.success("Address has been change");
          }
        })
        .catch(() => {
          this.$toast.error("Something went wrong");
        });
    },
    cleanForm() {
      this.street = "";
      this.number = "";
      this.lastName = "";
      this.extra = "";
      this.city = "";
      this.state = "";
      this.country = "";
      this.reference = "";
      this.cleanErrors();
    },
    cleanErrors() {
      Object.keys(this.errors).forEach((key) => {
        this.errors[key] = "";
      });
    },
  },
};
</script>